import React from "react";
import landingimg from "../images/Header.png";

function Landing() {
  return (
    <div className="">
      <img src={landingimg} className="img-fluid rounded-top w-100" alt="" />
    </div>
  );
}

export default Landing;
