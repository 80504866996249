import React from "react";
import "./Footer.css"; // You can create a CSS file for styling

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h5>Digimantra Labs</h5>
            <p className="smaller-font">
              Ludhiana Office - #1-#3, First Floor, Knowledge Park, S.T.E.P,
              Gill Road, Ludhiana, Punjab-141006
            </p>
            <p className="smaller-font">
              Mohali Office - Plot No. C - 212, Ground Floor, Phase 8B,
              Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab
              160055
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
