import React, { useState } from "react";
import Webcam from "react-webcam";
import camerapic from "../images/camera.png";

import { useFormik } from "formik";
import Landing from "./Landing";
import Footer from "./Footer";
import "./JoiningForm.css";
import axios from "axios";
import { Base_Url } from "../constant";
import { Button } from "react-bootstrap";

const videoConstraints = {
  width: 300, // Set the width to a smaller value
  height: 350, // Set the height to a larger value
  facingMode: "user",
};
const ref1 = React.createRef();

const JoiningForm = () => {
  const [file, setFile] = useState([]);

  const validate = (values) => {
    const errors = {};
    // Full Name Validation
    if (!values.fullName) {
      errors.fullName = "Required";
    } else if (values.fullName.length > 30) {
      errors.fullName = "Must be 15 characters or less";
    }

    // Mobile Number Validation
    if (!values.mobileNumber) {
      errors.mobileNumber = "Required";
    } else if (!/^[0-9]{10}$/.test(values.mobileNumber)) {
      errors.mobileNumber = "Must be a 10-digit number ";
    }
    if (!values.correspondenceAddress) {
      errors.correspondenceAddress = "Correspondence Address is required";
    }

    if (!values.permanentAddress) {
      errors.permanentAddress = "Permanent Address is required";
    }
    // Date of Birth Validation
    if (!values.dateOfBirth) {
      errors.dateOfBirth = "Date of Birth is required";
    } else {
      const dob = new Date(values.dateOfBirth);
      const currentDate = new Date();
      const age = currentDate.getFullYear() - dob.getFullYear();

      // Check if the user is less than 18 years old
      if (age < 18) {
        errors.dateOfBirth = "You must be at least 18 years old.";
      }
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      fullName: "",
      mobileNumber: "",
      correspondenceAddress: "",
      permanentAddress: "",
      jobTitle: "",
      lastJobTitle: "",
      lastJobLevel: "",
      currentEmployer: "",
      currentSector: "",
      gender: "",
      dateOfBirth: "",
      nationality: "",
      isSameAddress: false,
      experiences: Array(3).fill({
        totalYearsExperience: "",
        relevantYearsExperience: "",
      }),
      previousReportingTo: "",
      reportingMembers: "",
      countriesOfExperience: "",
      skills: Array(1).fill({
        skill: "",
        beginner: "",
        intermediate: "",
        advanced: "",
      }),
      firstDegree: "",
      mastersMbaPhd: "",
      qualifications: Array(4).fill({
        qualification: "",
      }),
      totalGrossSalary: "",
      monthlyNetSalary: "",
      performanceBasedPay: "",
      lastBonusReceived: "",
      otherCashBenefits: "",
      benefits: "",
      expectedAnnualGrossSalary: "",
      expectedMonthlyNetSalary: "",
      expectedBenefits: "",
      aboutMe: "",
      name: "",
      date: "",
      profileImageUrl: "",
    },
    validate,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      // console.log("formValues:::", values);
    },
  });

  const webcamRef = React.useRef(null);
  const [image, setImage] = useState(undefined);
  const [cameraEnabled, setCameraEnabled] = useState(false);
  const toggleCameraPermission = async () => {
    try {
      if (!cameraEnabled) {
        // Enable camera access
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (webcamRef.current) {
          webcamRef.current.video.srcObject = stream;
        }
      } else {
        // Disable camera access
        const tracks = webcamRef.current?.video.srcObject?.getTracks();
        if (tracks) {
          tracks.forEach((track) => {
            track.stop();
          });
          if (webcamRef.current) {
            webcamRef.current.video.srcObject = null;
          }
        }
      }

      // Toggle the cameraEnabled state
      setCameraEnabled(!cameraEnabled);
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const captureImage = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImage(imageSrc);

      const base64Data = imageSrc.split(",")[1];

      // Convert the base64 image data to a Blob
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const imageBlob = new Blob([byteArray], { type: "image/jpeg" });

      // Create a File object with a unique name
      const uniqueFileName = `image_${new Date().getTime()}.jpg`;
      const imageFile = new File([imageBlob], uniqueFileName, {
        type: "image/jpeg",
      });

      // Set the imageFile state with the captured file
      setFile(imageFile);
      handleUpload(imageFile); // You can implement this function to handle the file upload to the API
    }
  };

  function convertKeysToTitleCaseWithSpaces(obj) {
    const convertedObj = {};

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const titleCaseKey = key
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        convertedObj[titleCaseKey] = obj[key];
      }
    }

    return convertedObj;
  }

  function flattenObject(obj, parentKey = "") {
    let result = {};

    for (const key in obj) {
      const newKey = parentKey ? `${parentKey}_${key}` : key;

      if (Array.isArray(obj[key])) {
        for (let i = 0; i < obj[key].length; i++) {
          const flattenedItem = flattenObject(obj[key][i], `${newKey}${i + 1}`);
          result = { ...result, ...flattenedItem };
        }
      } else if (typeof obj[key] === "object") {
        const flattenedItem = flattenObject(obj[key], newKey);
        result = { ...result, ...flattenedItem };
      } else {
        result[newKey] = obj[key];
      }
    }

    return result;
  }
  const handleExportToExcel = () => {
    // console.log("formik.values", formik.values);

    const result = flattenObject(formik.values);
    console.log(result, "result");
    // const dataToSend = convertKeysToTitleCaseWithSpaces(result);
    // const csvBlob = exportToCSV([dataToSend]);

    sendCSVDataToAPI(result);
  };

  function exportToCSV(data) {
    const csvContent = JSONToCSV(data);
    const blob = new Blob([csvContent], { type: "text/csv" });
    return blob;
  }

  function JSONToCSV(jsonData) {
    const csvRows = [];

    // Extracting headers
    const headers = Object.keys(jsonData[0]);
    csvRows.push(headers.join(","));

    // Converting data to CSV rows
    jsonData.forEach((item) => {
      const values = headers.map((header) => item[header]);
      csvRows.push(values.join(","));
    });

    return csvRows.join("\n");
  }

  const sendCSVDataToAPI = async (data) => {
    try {
      const apiUrl = `${Base_Url}/v1/joining/genrate-joining-Form`;
      const payload = {};
      payload.userFields = [data];

      const response = await axios.post(apiUrl, payload, {});
      alert("Response saved successfully");
      formik.resetForm();
      setFile("");
      setImage("");
      setCameraEnabled(false);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleUpload = async (imageFile) => {
    if (!imageFile) {
      alert("Please choose a file first!");
    }
    try {
      const apiUrl = `${Base_Url}/v1/joining/user-image`;
      const formData = new FormData();
      formData.append("image", imageFile);
      const response = await axios.post(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      formik.setFieldValue(
        "profileImageUrl",
        response.data.data.profile_picture
      );
      alert("Image uploaded successfully");
      // setCameraEnabled(false);

      setImage(response.data.data.profile_picture);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  function getYesterday() {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Format the date as "YYYY-MM-DD" for the input element
    const yyyy = yesterday.getFullYear();
    const mm = String(yesterday.getMonth() + 1).padStart(2, "0");
    const dd = String(yesterday.getDate()).padStart(2, "0");

    return `${yyyy}-${mm}-${dd}`;
  }
  function renderYearOptions() {
    const options = [];
    for (let i = 1; i <= 20; i++) {
      options.push(
        <option key={i} value={i}>
          {i} {i === 1 ? "Year" : "Years"}
        </option>
      );
    }
    return options;
  }
  const addSkillRow = () => {
    formik.setValues({
      ...formik.values,
      skills: [
        ...formik.values.skills,
        { skill: "", beginner: "", intermediate: "", advanced: "" },
      ],
    });
  };

  const deleteSkillRow = (rowIndex) => {
    const newSkills = [...formik.values.skills];
    newSkills.splice(rowIndex, 1);
    formik.setValues({ ...formik.values, skills: newSkills });
  };
  return (
    <>
      <div
        className="container mt-4"
        ref={ref1}
        style={{
          backgroundColor: "#f4f4f4" /* Light gray background color */,
          // maxWidth: 400px;
          margin: " 0 auto",
          padding: "20px",
          borderRadius: "5px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Landing />
        <h2 className="mb-4">
          <img
            className="lazyloaded float-end"
            src="https://digimantralabs.com/wp-content/uploads/2023/06/dml-logo1.png"
            alt="DigiMantra Labs"
            data-ll-status="loaded"
          />
        </h2>{" "}
        <span>
          <h4 style={{ display: "flex" }}> New Joining Form</h4>
          <sup>(Applicant's Information)</sup>
        </span>
        <form className="m-3" onSubmit={formik.handleSubmit}>
          <div className="row mb-3 d-flex">
            <div className="col-md-6 ">
              <label htmlFor="fullName" className="form-label">
                Full Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="fullName"
                name="fullName"
                onChange={formik.handleChange}
                value={formik.values.fullName}
              />
              {formik.errors.fullName ? (
                <div className="error_message">{formik.errors.fullName}</div>
              ) : null}
            </div>
            <div className="col-md-6">
              <label htmlFor="mobileNumber" className="form-label">
                Mobile Number:
              </label>
              <input
                type="text"
                className="form-control"
                id="mobileNumber"
                name="mobileNumber"
                onChange={formik.handleChange}
                value={formik.values.mobileNumber}
                maxLength={10}
              />
              {formik.errors.mobileNumber ? (
                <div className="error_message">
                  {formik.errors.mobileNumber}
                </div>
              ) : null}
            </div>
          </div>
          <div className="row mb-3 d-flex">
            <div className="col-md-6">
              <label htmlFor="correspondenceAddress" className="form-label">
                Correspondence Address:
              </label>
              <textarea
                className="form-control"
                id="correspondenceAddress"
                name="correspondenceAddress"
                onChange={formik.handleChange}
                value={formik.values.correspondenceAddress}
                rows="3" // Set an initial number of rows (you can adjust this)
                style={{
                  minHeight: "50px", // Set a minimum height
                  resize: "none", // Disable manual resizing by the user
                }}
                maxLength={100}
              ></textarea>
            </div>
            <div className="col-md-6">
              <label htmlFor="permanentAddress" className="form-label">
                Permanent Address(if different from the address above):
              </label>
              <textarea
                className="form-control"
                id="permanentAddress"
                name="permanentAddress"
                onChange={formik.handleChange}
                value={formik.values.permanentAddress}
                disabled={formik.values.isSameAddress}
                rows="3" // Set an initial number of rows (you can adjust this)
                style={{
                  minHeight: "50px", // Set a minimum height
                  resize: "none", // Disable manual resizing by the user
                }}
                maxLength={100}
              ></textarea>
            </div>
          </div>
          <div className="row mb-3 d-flex">
            <div className="col-md-6"></div>
            <div className="col-md-6 d-flex justify-content-end align-items-center">
              <div className="form-check d-flex align-items-center ">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="isSameAddress"
                  name="isSameAddress"
                  onChange={(e) => {
                    // Update the formik value for the checkbox
                    formik.handleChange(e);

                    // Check if the checkbox is checked
                    if (e.target.checked) {
                      // Set the "Permanent Address" to match the "Correspondence Address"
                      formik.setFieldValue(
                        "permanentAddress",
                        formik.values.correspondenceAddress
                      );
                    }
                  }}
                  checked={formik.values.isSameAddress} // Make sure the checkbox reflects the formik value
                />
                <label className="form-check-label m-2" htmlFor="isSameAddress">
                  Same as Correspondence Address
                </label>
              </div>
            </div>
          </div>
          <div className="row mb-3 d-flex">
            <div className="col-md-6">
              <label htmlFor="jobTitle" className="form-label">
                Job Title of Position Sought:
              </label>
              <input
                type="text"
                className="form-control"
                id="jobTitle"
                name="jobTitle"
                // value={formData.job_title}
                // onChange={handleChange}
                onChange={formik.handleChange}
                value={formik.values.jobTitle}
                maxLength={50}
              />
            </div>
          </div>
          <div className="row mb-3 d-flex">
            <div className="col-md-6">
              <label htmlFor="lastJobTitle" className="form-label">
                Current/Last Job Title:
              </label>
              <input
                type="text"
                className="form-control"
                id="lastJobTitle"
                name="lastJobTitle"
                // value={formData.last_job_title}
                // onChange={handleChange}
                onChange={formik.handleChange}
                value={formik.values.lastJobTitle}
                maxLength={50}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="lastJobLevel" className="form-label">
                Current/Last Job Level:
              </label>
              <input
                type="text"
                className="form-control"
                id="lastJobLevel"
                name="lastJobLevel"
                onChange={formik.handleChange}
                value={formik.values.lastJobLevel}
                maxLength={50}
              />
            </div>
          </div>
          <div className="row mb-3 d-flex">
            <div className="col-md-6">
              <label htmlFor="currentEmployer" className="form-label">
                Current Employer:
              </label>
              <input
                type="text"
                className="form-control"
                id="currentEmployer"
                name="currentEmployer"
                onChange={formik.handleChange}
                value={formik.values.currentEmployer}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="currentSector" className="form-label">
                Current Sector:
              </label>
              <input
                type="text"
                className="form-control"
                id="currentSector"
                name="currentSector"
                onChange={formik.handleChange}
                value={formik.values.currentSector}
                maxLength={50}
              />
            </div>
          </div>
          <div className="row mb-3 d-flex">
            <div className="col-md-6">
              <label htmlFor="gender" className="form-label">
                Gender:
              </label>
              <select
                className="form-select"
                id="gender"
                name="gender"
                onChange={formik.handleChange}
                value={formik.values.gender}
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="col-md-6">
              <label htmlFor="dateOfBirth" className="form-label">
                Date of Birth:
              </label>
              <input
                type="date"
                className="form-control"
                id="dateOfBirth"
                name="dateOfBirth"
                onChange={formik.handleChange}
                value={formik.values.dateOfBirth}
                max={getYesterday()} // Set the max attribute to yesterday's date
              />
              {formik.errors.dateOfBirth ? (
                <div className="error_message">{formik.errors.dateOfBirth}</div>
              ) : null}
            </div>
          </div>
          <div className="row mb-3 d-flex">
            <div className="col-md-6">
              <label htmlFor="nationality" className="form-label">
                Nationality:
              </label>
              <select
                className="form-select"
                id="nationality"
                name="nationality"
                onChange={formik.handleChange}
                value={formik.values.nationality}
                defaultValue={"Indian"}
              >
                <option value="Indian">Indian</option>
                <option value="Others">Others</option>
              </select>
            </div>
          </div>
          <h4 className="mb-5 mt-5">Previous Employment</h4>
          <sup>(Please list your last 3 previousemployments with dates)</sup>
          {formik?.values?.experiences?.map((data, dIndex) => {
            return (
              <>
                <div className="row mb-3" key={dIndex}>
                  <div className="col-md-6">
                    <label
                      htmlFor="totalYearsExperience"
                      className="form-label"
                    >
                      Total Years of Experience:
                    </label>
                    <select
                      className="form-select"
                      id="totalYearsExperience"
                      name={`experiences[${dIndex}].totalYearsExperience`}
                      onChange={formik.handleChange}
                      value={
                        formik.values.experiences[dIndex].totalYearsExperience
                      }
                    >
                      {renderYearOptions()}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label
                      htmlFor="relevantYearsExperience"
                      className="form-label"
                    >
                      Relevant Years of Experience:
                    </label>
                    <select
                      className="form-select"
                      id="relevantYearsExperience"
                      name={`experiences[${dIndex}].relevantYearsExperience`}
                      onChange={formik.handleChange}
                      value={
                        formik.values.experiences[dIndex]
                          .relevantYearsExperience
                      }
                    >
                      {renderYearOptions()}
                    </select>
                  </div>
                </div>
              </>
            );
          })}
          <h4 className="mb-5 mt-5 ">Reporting Details</h4>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="previousReportingTo" className="form-label">
                Whom were you reporting to in your previous role? (Job title):
              </label>
              <input
                type="text"
                className="form-control"
                id="previousReportingTo"
                name="previousReportingTo"
                onChange={formik.handleChange}
                value={formik.values.previousReportingTo}
              />
            </div>
            <div className="col-md-6">
              <label
                htmlFor="currentlyReportedBy.jobTitle"
                className="form-label"
              >
                Who currently reports to you? (Job title & Number of People):
              </label>
              <input
                type="text"
                className="form-control"
                id="reportingMembers"
                name="reportingMembers"
                onChange={formik.handleChange}
                value={formik.values.reportingMembers}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="countriesOfExperience" className="form-label">
                Countries of Experience:
              </label>
              <input
                type="text"
                className="form-control"
                id="countriesOfExperience"
                name="countriesOfExperience"
                onChange={formik.handleChange}
                value={formik.values.countriesOfExperience}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <label className="form-label">
                What is your level of proficiency in relevance to your job
                application?
              </label>

              <table className="table col-md-12">
                <thead style={{ backgroundColor: "#007bff", color: "white" }}>
                  <tr>
                    <th>Skills</th>
                    <th>Beginner</th>
                    <th>Intermediate</th>
                    <th>Advanced</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody style={{ backgroundColor: "#f7f7f7" }}>
                  {formik?.values?.skills?.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td>
                        <input
                          type="text"
                          name={`skills[${rowIndex}].skill`}
                          value={formik.values.skills[rowIndex]?.skill}
                          onChange={formik.handleChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name={`skills[${rowIndex}].beginner`}
                          value={formik.values.skills[rowIndex]?.beginner}
                          onChange={formik.handleChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name={`skills[${rowIndex}].intermediate`}
                          value={formik.values.skills[rowIndex]?.intermediate}
                          onChange={formik.handleChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name={`skills[${rowIndex}].advanced`}
                          value={formik.values.skills[rowIndex]?.advanced}
                          onChange={formik.handleChange}
                        />
                      </td>
                      <td>
                        <Button onClick={() => deleteSkillRow(rowIndex)}>
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {formik?.values?.skills.length < 5 && (
                <Button
                  onClick={addSkillRow}
                  className="justify-content-center"
                >
                  +
                </Button>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="firstDegree" className="form-label">
                First Degree:
              </label>
              <input
                type="text"
                className="form-control"
                id="firstDegree"
                name="firstDegree"
                value={formik.values.firstDegree}
                onChange={formik.handleChange}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="mastersMbaPhd" className="form-label">
                Masters/MBA/PhD:
              </label>
              <input
                type="text"
                className="form-control"
                id="mastersMbaPhd"
                name="mastersMbaPhd"
                value={formik.values.mastersMbaPhd}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <label className="form-label">
                Professional Qualifications (Certifications/Awarding Body/Date)
              </label>
            </div>
            {formik?.values?.qualifications?.map((data, dIndex) => {
              return (
                <div className="col-md-6 mb-3" key={dIndex}>
                  <input
                    type="text"
                    className="form-control"
                    name={`qualifications[${dIndex}].qualification`}
                    value={formik.values.qualifications[dIndex].qualification}
                    placeholder={`Certifications/Awarding with date ${
                      dIndex + 1
                    }`}
                    onChange={formik.handleChange}
                  />
                </div>
              );
            })}
          </div>
          <h4 className="mb-5 mt-5">Remuneration Details</h4>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="totalGrossSalary" className="form-label">
                Total Guaranteed Annual Gross Salary:
              </label>
              <input
                type="text"
                className="form-control"
                id="totalGrossSalary"
                name="totalGrossSalary"
                onChange={formik.handleChange}
                value={formik.values.totalGrossSalary}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="monthlyNetSalary" className="form-label">
                Current Monthly Net Salary (After Tax):
              </label>
              <input
                type="text"
                className="form-control"
                id="monthlyNetSalary"
                name="monthlyNetSalary"
                onChange={formik.handleChange}
                value={formik.values.monthlyNetSalary}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="performanceBasedPay" className="form-label">
                Performance Based Pay (if applicable):
              </label>
              <input
                type="text"
                className="form-control"
                id="performanceBasedPay"
                name="performanceBasedPay"
                onChange={formik.handleChange}
                value={formik.values.performanceBasedPay}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="lastBonusReceived" className="form-label">
                Amount of Last Bonus Received (if applicable):
              </label>
              <input
                type="text"
                className="form-control"
                id="lastBonusReceived"
                name="lastBonusReceived"
                onChange={formik.handleChange}
                value={formik.values.lastBonusReceived}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="otherCashBenefits" className="form-label">
                Other Cash Benefits:
              </label>
              <input
                type="text"
                className="form-control"
                id="otherCashBenefits"
                name="otherCashBenefits"
                onChange={formik.handleChange}
                value={formik.values.otherCashBenefits}
              />
            </div>
          </div>
          <h4 className="mb-5 mt-5">Non Cash Section</h4>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="benefits" className="form-label">
                Benefits:
              </label>
              <input
                type="text"
                className="form-control"
                id="benefits"
                name="benefits"
                onChange={formik.handleChange}
                value={formik.values.benefits}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="expectedAnnualGrossSalary" className="form-label">
                Expected Annual Gross Salary:
              </label>
              <input
                type="text"
                className="form-control"
                id="expectedAnnualGrossSalary"
                name="expectedAnnualGrossSalary"
                onChange={formik.handleChange}
                value={formik.values.expectedAnnualGrossSalary}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="expectedMonthlyNetSalary" className="form-label">
                Expected Monthly Net Salary:
              </label>
              <input
                type="text"
                className="form-control"
                id="expectedMonthlyNetSalary"
                name="expectedMonthlyNetSalary"
                onChange={formik.handleChange}
                value={formik.values.expectedMonthlyNetSalary}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="expectedBenefits" className="form-label">
                Expected Benefits (Please Specify):
              </label>
              <input
                type="text"
                className="form-control"
                id="expectedBenefits"
                name="expectedBenefits"
                onChange={formik.handleChange}
                value={formik.values.expectedBenefits}
              />
            </div>
          </div>
          <h4 className="mb-5 mt-5">About Section</h4>
          <div className="row mb-3">
            <div className="col-md-6">
              <span className="input-group-text">About:</span>
              <textarea
                type="text"
                className="form-control"
                placeholder="Tell us about you"
                rows="3" // Set an initial number of rows (you can adjust this)
                style={{
                  height: "300px", // Set a minimum height
                  resize: "none", // Disable manual resizing by the user
                }}
                name="aboutMe"
                value={formik.values.aboutMe}
                onChange={formik.handleChange}
              />
            </div>
            <div className="col-md-6">
              {!image && cameraEnabled ? (
                <div>
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                  />
                </div>
              ) : cameraEnabled ? (
                <img src={image} alt="Profile-pic" />
              ) : (
                <img
                  src={camerapic}
                  alt="Profile-pic"
                  style={{ width: "300px" }}
                />
              )}
              <br />
              <div>
                <button
                  className="btn btn-primary btn-sm m-2"
                  onClick={() => {
                    if (image) {
                      setImage(false);
                    } else {
                      // If no image is captured, allow the user to capture/take a picture
                      cameraEnabled ? captureImage() : toggleCameraPermission();
                    }
                  }}
                >
                  {image
                    ? "Retake"
                    : cameraEnabled
                    ? "Capture"
                    : "Take a Picture"}
                </button>
              </div>
            </div>
          </div>
          <div>
            <h4 className="mb-5 mt-5">Consent and Signature</h4>

            <div className="mb-4">
              <p>
                I authorize Digimantra Labs and its representatives to obtain
                information from any of my past employers, any administrators or
                relevant members of institutions with which I have been
                associated, and any other person (including past and present
                professional liability insurers, individuals, professionals, or
                other organizations, licensing authorities, and schools) who may
                have information pertaining to my character and qualifications.
              </p>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="name" className="form-label">
                  Name:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="date" className="form-label">
                  Date:
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="date"
                  name="date"
                  onChange={formik.handleChange}
                  value={formik.values.date}
                  max={getYesterday()} // Set the max attribute to yesterday's date
                />
              </div>
            </div>

            <div className="mt-4">
              <p>
                Note: All information provided on this form and during the
                interview process will be verified.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              <button
                onClick={handleExportToExcel}
                className="btn btn-primary"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
        <Footer />
      </div>
    </>
  );
};

export default JoiningForm;
